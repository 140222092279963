import React, { useState, useEffect } from "react"
import moment from "moment"

import { AccordionGroup, Box, Wrapper } from "components"

import ReportList from "./_reportList"

function sortByYear(a, b) {
  let comparison = 0
  if (a.year > b.year) {
    comparison = -1
  } else {
    comparison = 1
  }
  return comparison
}

function sortByTag(a, b) {
  let comparison = 0
  if (a.tags && b.tags && a.tags[0] && b.tags[0]) {
    if (moment(a.tags[0]).unix() > moment(b.tags[0]).unix()) {
      comparison = -1
    } else {
      comparison = 1
    }
  }
  return comparison
}

const Reports = ({ items, downloadDocument }) => {
  const [years, setYears] = useState([])

  const calculateYears = () => {
    // Iterate through reports. Get an array of the years contained in this list, then add item to proper year

    let activeYears = []
    let yearsAndData = []

    items.forEach(item => {
      if (item.created_at) {
        let itemYear = moment(item.tags[0]).year()
        if (activeYears.indexOf(itemYear) === -1) {
          activeYears.push(itemYear)
          yearsAndData.push({ year: itemYear, items: [] })
        }

        yearsAndData.forEach((reportYear, index) => {
          if (reportYear.year === itemYear) {
            reportYear.items.push(item)
          }
        })
      }
    })

    // Sort results by year, then set years
    yearsAndData.sort(sortByYear)
    yearsAndData.forEach(reportYear => {
      reportYear.items.sort(sortByTag)
    })
    setYears(yearsAndData)
  }

  useEffect(() => {
    calculateYears()
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  return (
    <Wrapper>
      <Box my={["layout.4", "layout.6"]}>
        {/* Most recent year (not part of Accordion Group) */}
        {years && years[0] && (
          <ReportList
            title={years[0].year}
            items={years[0].items}
            downloadDocument={downloadDocument}
          />
        )}
        {/* Previous years in an accordion */}
        <AccordionGroup
          items={years
            .filter((item, index) => index > 0)
            .map((year, index) => ({
              heading: year.year,
              children: (
                <ReportList
                  items={year.items}
                  downloadDocument={downloadDocument}
                  key={"ReportList" + index}
                  index={index}
                />
              ),
            }))}
        />
      </Box>
    </Wrapper>
  )
}

export default Reports
