import React, { useState } from "react"
import css from "@styled-system/css"
import moment from "moment"

import { Box, Flex, Heading, Text } from "components"

function ListItem({ onClick, context, created_at, tags }) {
  const [isHovering, setIsHovering] = useState(false)
  return (
    <Flex
      mb="layout.2"
      pb="layout.2"
      borderBottom="1px solid"
      borderColor="wash"
    >
      <Box mr="layout.1">
        <Heading
          children="PDF"
          as="span"
          size={600}
          display="block"
          px="spacing.2"
          py="spacing.2"
          color="background"
          bg="primary"
        />
      </Box>
      <Flex flexDirection={["column", "row"]} flex={1} alignItems="flex-start">
        <Box display="flex" pr={"layout.2"} flex={1}>
          <Box
            as="button"
            flex={1}
            m={0}
            p={0}
            border={0}
            borderRadius={0}
            textAlign="left"
            bg="transparent"
            onClick={onClick}
            onMouseEnter={() => setIsHovering(true)}
            onMouseLeave={() => setIsHovering(false)}
            className={isHovering === true ? "isHovering" : ""}
            css={css({
              appearance: "none",
              transition: "color 300ms ease-in-out",
              "&.isHovering": {
                color: "primary",
              },
            })}
          >
            {context && (
              <Text
                children={context.custom.caption}
                size={500}
                color="inherit"
              />
            )}
          </Box>
        </Box>
        {tags && tags[0] && (
          <Box>
            <Text
              children={moment(tags[0]).format("YYYY-MM-DD")}
              color="alt"
              textAlign="right"
            />
          </Box>
        )}
      </Flex>
    </Flex>
  )
}

const ReportList = ({ title, items, downloadDocument, index }) => (
  <Box>
    <Flex
      flexWrap="wrap"
      m="-4px"
      css={css({
        "> *": {
          width: ["100%"],
          p: "4px",
        },
      })}
    >
      <Heading children={title} size={700} mb="layout.2" />
      {items.map((item, index) => (
        <ListItem
          {...item}
          onClick={() => {
            downloadDocument(item)
          }}
          key={"reportListItem" + index}
        />
      ))}
    </Flex>
  </Box>
)

export default ReportList
