import React from "react"
import { Link } from "gatsby"

import { Button, Box, Heading, Text, Wrapper } from "components"

const CallToAction = () => (
  <Wrapper>
    <Box maxWidth={1080 / 1.5}>
      <Heading size={600} children="Looking for more?" />
      <Text
        children="We'd love to hear from you. Contact us for more information."
        size={400}
        mt="layout.1"
      />
      <Button as={Link} to="/contact/" mt="layout.3">
        Contact us
      </Button>
    </Box>
  </Wrapper>
)

export default CallToAction
