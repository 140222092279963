import React, { useState, useEffect } from "react"
import Helmet from "react-helmet"
import { navigate } from "gatsby"

import CallToAction from "./_callToAction"
import Reports from "./_reports"

import { PageHeader, Flex, SEO, Spinner } from "components"
import { useAuth } from "contexts/auth"

import fetchPonyfill from "fetch-ponyfill"

const { fetch } = fetchPonyfill()

function ShareholdersPage() {
  const { user } = useAuth()
  const [approved, setApproved] = useState(false)
  const [documents, setDocuments] = useState(undefined)

  // Retrieve documents from Cloudinary via Netlify Function
  const retrieveDocuments = () => {
    if (user && user.token) {
      const myAuthHeader = "Bearer " + user.token.access_token //creates the bearer token
      fetch("/.netlify/functions/shareholder-docs", {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
          Authorization: myAuthHeader,
        },
        credentials: "include",
      })
        .then(response => response.json())
        .then(data => {
          if (data.success) {
            setDocuments(data.documents)
          } else {
            // TODO: Error
          }
        })
        .catch(error => {
          // TODO: Error
        })
    }
  }

  // Retrieve documents from Cloudinary via Netlify Function
  const downloadDocument = document => {
    if (user && user.token) {
      const myAuthHeader = "Bearer " + user.token.access_token //creates the bearer token
      fetch("/.netlify/functions/download-doc", {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
          Authorization: myAuthHeader,
        },
        body: JSON.stringify({ document: document.public_id }),
        credentials: "include",
      })
        .then(response => response.json())
        .then(data => {
          if (data.success) {
            window.open(data.link)
          }
        })
        .catch(error => {
          // TODO: Error
        })
    }
  }

  // Enforce logged in state to see this page
  useEffect(() => {
    // If not authorized, send user away
    if (!user || !user.id) {
      navigate("/shareholders/")
    }
    // If authorized, check the role of the user to see if they've been approved
    if (
      user.app_metadata &&
      user.app_metadata.roles &&
      user.app_metadata.roles[0]
    ) {
      // If approved, set it, and query for documents from Cloudinary secure repo
      if (user.app_metadata.roles[0] === "Approved") {
        setApproved(true)
        retrieveDocuments()
      }
    }
  }, [user])

  const page = {
    data: {
      shareholders: {
        title: "<h1>Shareholders</h1>",
        callToActionTitle: "Looking for more?",
        callToActionBody:
          "We'd love to hear from you. Contact us for more information!",
        unauthorizedTitle: "<h1>Your account has not yet been approved.</h1>",
        unauthorizedBody:
          "<p>Once we verify that you're a shareholder, you'll receive an email stating that you've been authorized to view this section.</p>",
      },
    },
  }

  return (
    <>
      <Helmet>
        <meta name="robots" content="noindex" />
      </Helmet>
      <SEO title="Shareholders | Teine" />
      {!approved && (
        <PageHeader
          data={{
            heading: page.data.shareholders.unauthorizedTitle,
            description: page.data.shareholders.unauthorizedBody,
          }}
        />
      )}

      {approved && (
        <>
          <PageHeader
            data={{
              heading: page.data.shareholders.title,
              description: page.data.shareholders.subtext,
            }}
          />
          {!documents && (
            <Flex minHeight="66vh" justifyContent="center" alignItems="center">
              <Spinner />
            </Flex>
          )}
          {documents && (
            <Reports items={documents} downloadDocument={downloadDocument} />
          )}
          <CallToAction
            title={page.data.shareholders.callToActionTitle}
            body={page.data.shareholders.callToActionBody}
          />
        </>
      )}
    </>
  )
}

export default ShareholdersPage
